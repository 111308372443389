import { render, staticRenderFns } from "./exam-details.vue?vue&type=template&id=aa0ece26&scoped=true"
import script from "./exam-details.vue?vue&type=script&lang=ts&setup=true"
export * from "./exam-details.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./exam-details.vue?vue&type=style&index=0&id=aa0ece26&prod&scoped=true&lang=css"
import style1 from "./exam-details.vue?vue&type=style&index=1&id=aa0ece26&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa0ece26",
  null
  
)

export default component.exports