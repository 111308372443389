/* eslint-disable  no-unused-vars*/
import Vue from "vue";
import store from "../store";

import VueRouter, { RouteConfig } from "vue-router";
import Dashboard from "../views/dashboard.vue";
import Home from "../views/home.vue";
import MyCourseUnits from "../views/course-units/my-course-units.vue";
import Settings from "../views/settings.vue";
import Help from "../views/help.vue";
import StudentProfile from "../views/profiles/student-profile.vue";
import StaffProfile from "../views/profiles/staff-profile.vue";
import UserRequests from "../views/user-requests.vue";
import ResourceDetails from "../views/resource-details.vue";
import Faculties from "../views/faculties.vue";
import FacultyDetail from "../views/faculty-detail.vue";
import DepartmentCourses from "../views/department-courses.vue";
import Staff from "../views/staff.vue";
import IntakeBatches from "../views/intake-batches.vue";
import CourseBatchDetail from "../views/course-batch-detail.vue";
import Students from "../views/students.vue";
import CourseIntakeBatchBillingStructure from "../views/course-intake-batch-billing-structure.vue";
import BillingSystems from "../views/billing-systems.vue";
import BillingItems from "../views/billing-items.vue";
import LiveLectures from "../views/live-lectures.vue";
import LiveLecture from "../views/live-lecture.vue";
import IntakeBatchCalendar from "../views/intake-batch-calendar.vue";
import CourseUnitResources from "../views/course-unit-resources.vue";
import RecordedLecture from "../views/recorded-lecture.vue";
import LectureAttendance from "../views/lecture-attendance.vue";
import NoticeBoard from "../views/notice-board.vue";
import Resources from "../views/resources.vue";
import ResourceContentDetails from "../views/resource-content-details.vue";
import AddExamination from "../views/examinations/add-examination.vue";
import ExamDetails from "../views/examinations/exam-details.vue";
import AnswerSheet from "../views/examinations/answer-sheet.vue";
import ExamSubmissions from "../views/examinations/exam-submissions.vue";
import EditExamination from "../views/examinations/edit-examination.vue";
import ExamStatistics from "../views/examinations/exam-statistics.vue";
import LecturerLiveLectures from "../views/lecturer-live-lectures.vue";
import EnterEmailVerificationCode from "../views/enter-email-verification-code.vue";
import EnterTOTPVerificationCode from "../views/enter-totp-verification-code.vue";
import AdminChat from "../views/admin-chat.vue";
import UploadResourcesForMutilpeModules from "../views/upload-resources-for-mutilpe-modules.vue";
import UploadResourcesForMultipleCohorts from "../views/upload-resources-for-multiple-cohorts.vue";
import UpdateResourcesForMultipleModules from "../views/update-resources-for-multiple-modules.vue";
import UpdateResourcesForMultipleCohorts from "../views/update-resources-for-multiple-cohorts.vue";
import Grading from "../views/grading.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/dashboard/home",
    meta: { requiresLogin: true },
  },

  {
    path: "/enter-email-verification-code",
    name: "EnterEmailVerificationCode",
    component: EnterEmailVerificationCode,
  },

  {
    path: "/enter-totp-verification-code",
    name: "EnterTOTPVerificationCode",
    component: EnterTOTPVerificationCode,
  },

  {
    path: "/ongoing-lecture",
    name: "OngoingLecture",
    component: () =>
      import(
        /* webpackChunkName: "OngoingLecture" */ "../views/ongoing-lecture.vue"
      ),
    meta: { requiresLogin: true },
  },

  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/forgot-password.vue"
      ),
    meta: { requiresLogin: false },
  },
  {
    path: "/change-default-password",
    name: "ChangeDefaultPassword",
    component: () =>
      import(
        /* webpackChunkName: "ChangeDefaultPassword" */ "../views/change-default-password.vue"
      ),
    meta: { requiresLogin: false },
  },
  {
    path: "/enter-verification-code",
    name: "EnterVerificationCode",
    component: () =>
      import(
        /* webpackChunkName: "EnterVerificationCode" */ "../views/enter-verification-code.vue"
      ),
    meta: { requiresLogin: false },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "../views/reset-password.vue"
      ),
    meta: { requiresLogin: false },
  },
  {
    path: "/scan-lunch-coupon",
    name: "ScanLunchCoupon",
    component: () =>
      import(
        /* webpackChunkName: "scan-lunch-coupon" */ "../views/cafeteria/scan-lunch-coupon.vue"
      ),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
      },
      {
        path: "staff",
        name: "Staff",
        component: Staff,
      },
      {
        path: "intake-batches",
        name: "IntakeBatches",
        component: IntakeBatches,
      },
      {
        path: "intakes/:courseIntakeBatchId/intake-groups",
        name: "IntakeGroups",
        component: () =>
          import(
            /* webpackChunkName: "IntakeGroups" */ "../views/intake-groups.vue"
          ),
      },
      {
        path: "student-application/edit-student-applicant/:studentId",
        name: "EditStudentApplicant",
        component: () =>
          import(
            /* webpackChunkName: "edit-student-admission" */ "../views/edit-student-applicant.vue"
          ),
      },
      {
        path: "student-application",
        name: "StudentApplication",
        component: () =>
          import(
            /* webpackChunkName: "student-admissions" */ "../views/student-application.vue"
          ),
      },
      {
        path: "course-batch-detail/:intakeId",
        name: "CourseBatchDetail",
        component: CourseBatchDetail,
      },
      {
        path: "students",
        name: "Students",
        component: Students,
      },
      {
        path: "live-lectures/:id",
        name: "LecturerLiveLectures",
        component: LecturerLiveLectures,
      },
      {
        path: "live-lectures",
        name: "LiveLectures",
        component: LiveLectures,
      },

      {
        path: "live-lecture",
        name: "LiveLecture",
        component: LiveLecture,
      },
      {
        path: "course/:cId/course-units",
        name: "MyCourseUnits",
        component: MyCourseUnits,
      },
      {
        path: "course-unit-resources/:cuId",
        name: "CourseUnitResources",
        component: CourseUnitResources,
        meta: { requiresLogin: true },
      },
      {
        path: "resource-details",
        name: "ResourceDetails",
        component: ResourceDetails,
        meta: { requiresLogin: true },
      },
      {
        path: "batch-calendar/:batchId",
        name: "IntakeBatchCalendar",
        component: IntakeBatchCalendar,
      },
      {
        path: "settings",
        name: "Settings",
        component: Settings,
      },
      {
        path: "help",
        name: "Help",
        component: Help,
      },
      {
        path: "student-profile/:sId",
        name: "StudentProfile",
        component: StudentProfile,
      },
      {
        path: "staff-profile/:sId",
        name: "StaffProfile",
        component: StaffProfile,
      },
      {
        path: "chat-inquiries",
        name: "chat-inquiries",
        component: AdminChat,
      },
      {
        path: "user-requests",
        name: "UserRequests",
        component: UserRequests,
      },
      {
        path: "faculties",
        name: "Faculties",
        component: Faculties,
      },
      {
        path: "faculty/:fId/departments",
        name: "FacultyDetail",
        component: FacultyDetail,
      },
      {
        path: "department/:dId/courses",
        name: "DepartmentCourses",
        component: DepartmentCourses,
      },
      {
        path: "curriculum/:courseId",
        name: "Curriculum",
        component: () =>
          import(
            /* webpackChunkName: "curriculum" */ "../views/curriculum.vue"
          ),
      },
      {
        path: "transcript-curriculum/:courseId",
        name: "TranscriptCurriculum",
        component: () =>
          import(
            /* webpackChunkName: "transcriptCurriculum" */ "../views/transcriptCurriculum.vue"
          ),
      },
      {
        path: "recorded-lecture",
        name: "RecordedLecture",
        component: RecordedLecture,
      },
      {
        path: "lecture-attendance",
        name: "LectureAttendance",
        component: LectureAttendance,
      },
      {
        path: "notice-board",
        name: "NoticeBoard",
        component: NoticeBoard,
      },
      {
        path: "resources",
        name: "Resources",
        component: Resources,
      },
      {
        path: "upload-resources-for-multiple-cohorts",
        name: "UploadResourcesForMultipleCohorts",
        component: UploadResourcesForMultipleCohorts,
      },
      {
        path: "upload-resources-for-multiple-modules",
        name: "UploadResourcesForMutilpeModules",
        component: UploadResourcesForMutilpeModules,
      },
      {
        path: "update-resources-for-multiple-modules/:resourceId",
        name: "UpdateResourcesForMultipleModules",
        component: UpdateResourcesForMultipleModules,
      },
      {
        path: "update-resources-for-multiple-cohorts/:resourceId",
        name: "UpdateResourcesForMultipleCohorts",
        component: UpdateResourcesForMultipleCohorts,
      },
      {
        path: ":rId/resource-content-details",
        name: "ResourceContentDetails",
        component: ResourceContentDetails,
      },
      {
        path: "examination-sets",
        name: "ExaminationSets",
        component: () =>
          import(
            /* webpackChunkName: "examination-sets" */ "../views/examinations/examination-sets.vue"
          ),
      },
      {
        path: "course-work-results-management/:courseWorkId",
        name: "course-work-results-management",
        component: () =>
          import(
            /* webpackChunkName: "course-work-results-management" */ "../components/courseworks/course-work-results-management.vue"
          ),
      },
      {
        path: "exam-mark-sheets/:examinationId",
        name: "ExaminationResultsSheet",
        component: () =>
          import(
            /* webpackChunkName: "exam-results-management" */ "../views/examinations/exam-mark-sheets.vue"
          ),
      },
      {
        path: "examinations/:examSetId/",
        name: "Examinations",
        component: () =>
          import(
            /* webpackChunkName: "examination-set-details" */ "../views/examinations/examinations.vue"
          ),
      },
      {
        path: "exam-results-management/:examinationId",
        name: "ExaminationResults",
        component: () =>
          import(
            /* webpackChunkName: "exam-results-management" */ "../views/examinations/exam-results-management.vue"
          ),
      },
      {
        path: "student-examination-set-permits/:examSetId",
        name: "StudentExaminationSetPermits",
        component: () =>
          import(
            /* webpackChunkName: "student-examination-set-permits" */ "../views/examinations/student-examination-set-permits.vue"
          ),
      },
      // {
      //   path: "student-examination-permits/:examId",
      //   name: "StudentExaminationPermits",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "student-examination-permits" */ "../views/examinations/student-examination-permits.vue"
      //     ),
      // },
      {
        path: "exam-prerequisites/:examId",
        name: "ManageExamPrerequisites",
        component: () =>
          import(
            /* webpackChunkName: "examination-prerequisites" */ "../views/examinations/examination-prerequisites.vue"
          ),
      },
      {
        path: ":examination_set_id/examinations/add",
        name: "AddExamination",
        component: AddExamination,
      },
      {
        path: ":examination_set_id/examinations/edit/:examination_id",
        name: "EditExamination",
        component: EditExamination,
      },
      {
        path: "result-sheets/faculties/:examId",
        name: "ExamSetsWithFaculties",
        component: () =>
          import(
            /* webpackChunkName: "faculties-with-approved-marksheets" */ "../views/examinations/faculties-with-approved-marksheets.vue"
          ),
      },

      {
        path: "result-sheets/faculties/examinations/:examId/faculties/:facultyId",
        name: "FacultyWithApprovedMarkSheets",
        component: () =>
          import(
            /* webpackChunkName: "intakes-result-sheets-per-faculty" */ "../views/examinations/intakes-result-sheets-per-faculty.vue"
          ),
      },
      {
        path: "examinations/result-sheets/course-intake-batch/:courseIntakeBatchId/examinations/:examSetId",
        name: "StudentsWithInAnIntakeSelection",
        component: () =>
          import(
            /* webpackChunkName: "students-result-sheet.vue" */ "../views/examinations/students-result-sheet.vue"
          ),
      },
      {
        path: "topics",
        name: "Topics",
        component: () =>
          import(/* webpackChunkName: "Topics" */ "../views/topics.vue"),
      },

      {
        path: ":examId/exam-details",
        name: "ExamDetails",
        component: ExamDetails,
      },
      {
        path: ":answerId/answer-sheet",
        name: "AnswerSheet",
        component: AnswerSheet,
      },
      {
        path: ":examId/exam-submissions",
        name: "ExamSubmissions",
        component: ExamSubmissions,
      },
      {
        path: "exam-statistics",
        name: "ExamStatistics",
        component: ExamStatistics,
      },
      {
        path: "course-work/:courseWorkSetId",
        name: "courseWork",
        component: () =>
          import(
            /* webpackChunkName: "course-work" */ "../views/courseworks/course-work.vue"
          ),
      },
      {
        path: "course-work/edit/:courseWorkId",
        name: "EditCourseWork",
        component: () =>
          import(
            /* webpackChunkName: "course-work-edit" */ "../views/courseworks/edit-course-work.vue"
          ),
      },
      {
        path: "course-work-details/:courseWorkId",
        name: "CourseWorkDetails",
        component: () =>
          import(
            /* webpackChunkName: "course-work-details" */ "../views/courseworks/course-work-details.vue"
          ),
      },
      {
        path: "manage-elections",
        name: "ManageElections",
        component: () => import("../views/manage-elections.vue"),
      },
      {
        path: "manage-elections/election-categories/:electionId",
        name: "ElectionCategories",
        component: () => import("../views/election-categories.vue"),
      },
      {
        path: "manage-elections/election-categories/candidates/:categoryId",
        name: "Candidates",
        component: () => import("../views/candidates.vue"),
      },
      {
        path: "elections/intro",
        name: "Elections",
        component: () => import("../views/elections.vue"),
      },
      {
        path: "elections/candidates",
        name: "ElectionCandidates",
        component: () => import("../views/election-candidates.vue"),
      },

      {
        path: "recurring-lectures",
        name: "RecurringLectures",
        component: () => import("../views/recurring-live-lectures.vue"),
      },

      {
        path: "recurring-lectures",
        name: "RecurringLectures",
        component: () => import("../views/recurring-live-lectures.vue"),
      },

      {
        path: "student-results/:studentId",
        name: "StudentResults",
        component: () => import("../views/student-results.vue"),
      },

      {
        path: "student-modules-history/:studentId",
        name: "StudentModulesHistory",
        component: () => import("../views/student-modules-history.vue"),
      },

      {
        path: "failed-student-modules/:studentId",
        name: "FailedStudentModules",
        component: () =>
          import(
            /* webpackChunkName: "FailedStudentModules" */ "../views/failed-student-modules.vue"
          ),
      },

      // {
      //    path: "student-financial-statements",
      //    name: "StudentFinancialStatements",
      //    component: () => import("../views/students-financial-statements.vue"),
      // },

      {
        path: "student-transactions/:studentId",
        name: "StudentTransactions",
        component: () => import("../views/student-transactions.vue"),
      },

      {
        path: "intake-batches/new-applicant/:intakeId",
        name: "NewAdmission",
        component: () =>
          import(
            /* webpackChunkName: "new-admission" */ "../views/new-applicant.vue"
          ),
        meta: { requiresLogin: true },
      },

      {
        path: "Configurations",
        name: "Configurations",
        component: () => import("../views/configurations.vue"),
      },
      {
        path: "academic-year-configurations",
        name: "AcademicYearConfigurations",
        component: () =>
          import(
            /* webpackChunkName: "academic-year-configurations" */ "../views/academic-year-configurations.vue"
          ),
      },
      {
        path: "trimester-management",
        name: "TrimesterManagement",
        component: () =>
          import(
            /* webpackChunkName: "trimester-management" */ "../views/trimester-management.vue"
          ),
      },
      {
        path: "billing-structure/:intakeBatchId",
        name: "CourseIntakeBatchBillingStructure",
        component: CourseIntakeBatchBillingStructure,
        props: true,
      },
      {
        path: "billing-systems",
        name: "BillingSystems",
        component: BillingSystems,
      },
      {
        path: "billing-items",
        name: "BillingItems",
        component: BillingItems,
      },

      {
        path: "payments",
        name: "Payments",
        component: () =>
          import(
            /* webpackChunkName: "transactions" */ "../views/payments.vue"
          ),
        meta: { requiresLogin: true },
      },
      {
        path: "payments-pending-confirmation",
        name: "PaymentsPendingConfirmation",
        component: () =>
          import(
            /* webpackChunkName: "payments-pending-confirmation" */ "../views/payments-pending-confirmation.vue"
          ),
        meta: { requiresLogin: true },
      },
      {
        path: "website-payments",
        name: "OnlinePayments",
        component: () =>
          import(
            /* webpackChunkName: "online-transactions" */ "../views/website-payments.vue"
          ),
        meta: { requiresLogin: true },
      },
      {
        path: "financial-reports",
        name: "FinancialReports",
        component: () =>
          import(
            /* webpackChunkName: "financial-reports" */ "../views/financial-reports.vue"
          ),
        meta: { requiresLogin: true },
      },
      {
        path: "online-applications/edit-online-application/:onlineApplicationId",
        name: "EditOnlineApplication",
        component: () =>
          import(
            /* webpackChunkName: "edit-online-application." */ "../views/edit-online-application.vue"
          ),
      },
      {
        path: "grading",
        name: "Grading",
        component: Grading,
      },
      {
        path: "students/edit-student/:studentId",
        name: "EditStudent",
        component: () =>
          import(
            /* webpackChunkName: "edit-student" */ "../views/edit-student.vue"
          ),
      },
      {
        path: "course-work-sets",
        name: "CourseWorkSets",
        component: () =>
          import(
            /* webpackChunkName: "examination-sets" */ "../views/courseworks/course-work-sets.vue"
          ),
      },
      {
        path: "cafeteria-management",
        name: "CafeteriaManagement",
        component: () =>
          import(
            /* webpackChunkName: "cafeteria-management" */ "../views/cafeteria/cafeteria-management.vue"
          ),
      },
      {
        path: "cafeteria",
        name: "Cafeteria",
        component: () =>
          import(
            /* webpackChunkName: "cafeteria" */ "../views/cafeteria/cafeteria.vue"
          ),
      },
      {
        path: "generate-coupon",
        name: "GenerateCoupon",
        component: () =>
          import(
            /* webpackChunkName: "generate-coupon" */ "../views/cafeteria/generate-coupon.vue"
          ),
      },
      {
        path: "previous-coupons",
        name: "PreviousCoupons",
        component: () =>
          import(
            /* webpackChunkName: "previous-coupons" */ "../views/cafeteria/previous-coupons.vue"
          ),
      },
      {
        path: "online-applications",
        name: "OnlineApplications",
        component: () =>
          import(
            /* webpackChunkName: "online-applications" */ "../views/online-applications.vue"
          ),
      },
      {
        path: "exclusion-days",
        name: "ExclusionDays",
        component: () =>
          import(
            /* webpackChunkName: "exclusion-days" */ "../views/exclusion-days.vue"
          ),
      },
      {
        path: "resit-applications",
        name: "ResitApplication",
        component: () =>
          import(
            /* webpackChunkName: "resit-applications" */ "../views/resit-applications.vue"
          ),
      },
      {
        path: "retake-applications",
        name: "RetakeApplication",
        component: () =>
          import(
            /* webpackChunkName: "retake-applications" */ "../views/retake-applications.vue"
          ),
      },
      {
        path: "graduation-center",
        name: "GraduationCenter.",
        component: () =>
          import(
            /* webpackChunkName: "graduation-center" */ "../views/graduation-center.vue"
          ),
      },
      {
        path: "graduating-students/:graduationListId",
        name: "GraduatingStudents",
        component: () =>
          import(
            /* webpackChunkName: "graduating-students" */ "../views/graduating-students.vue"
          ),
      },
      {
        path: "scholarships",
        name: "Scholarships",
        component: () =>
          import(
            /* webpackChunkName: "scholarships" */ "../views/scholarships.vue"
          ),
      },
      {
        path: "programme-change-requests",
        name: "ProgrammeChangeRequests",
        component: () =>
          import(
            /* webpackChunkName: "programme-change-requests" */ "../views/programme-change-requests.vue"
          ),
      },
      {
        path: "intake-change-requests",
        name: "StudentIntakeChangeRequests",
        component: () =>
          import(
            /* webpackChunkName: "intake-change-requests" */ "../views/student-intake-change-requests.vue"
          ),
      },
      {
        path: "session-change-requests",
        name: "SessionChangeRequests",
        component: () =>
          import(
            /* webpackChunkName: "session-change-requests" */ "../views/session-change-requests.vue"
          ),
      },

      //In case the route is anything else
      {
        path: "*",
        redirect: "/dashboard/home",
      },
    ],
  },

  //In case the route is anything else
  {
    path: "*",
    redirect: "/dashboard/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

router.beforeResolve((to, from, next) => {
  if (to.name === "Login" && store.getters.isLoggedIn) {
    next({ path: "/dashboard/home" });
  }

  next();
  //Check if login is required for Routes with meta data loginRequired!
  // if (to.matched.some((record) => record.meta.requiresLogin)) {
  //   if (!store.getters.isLoggedIn) {
  //     //Redirect to Login Page
  //     next({ path: "/login" });
  //   } else next();
  // } else next();
});

export default router;
